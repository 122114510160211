import { useDispatch } from 'react-redux';

import { InfoModal } from 'components/modal';
import { createPrice } from 'services/utils';
import { AppActions } from 'screens/app';
import { useReduxSelector } from 'services/hooks';
import { Z_INDEXES, Theme } from 'themes';

const createText = (money: number) =>
  `Your rich uncle will give you an interest-free loan. You can only use up till ${createPrice(
    money
  )}`;

const LoanModal = () => {
  const minimalMoney = useReduxSelector((redux) => redux.app.minimalMoney);

  const dispatch = useDispatch();

  const onClose = () => dispatch(AppActions.closeNegativeMoney());

  return (
    <InfoModal
      zIndex={Z_INDEXES.NEGATIVE_BALANCE_MODAL}
      isScreenModal={false}
      buttonText="OK"
      onCloseModal={onClose}
      title={<Theme.Text>{createText(minimalMoney)}</Theme.Text>}
      isMultiClickButton
    />
  );
};

export const LoanModalContainer = () => {
  const isOpen = useReduxSelector(
    (redux) => redux.app.isOpenNegativeMoneyModal
  );

  if (!isOpen) {
    return null;
  }

  return <LoanModal />;
};
