import { Grid, Text } from 'components/ui';
import { InfoImageModal } from 'components/modal';
import { Theme, typographyCss } from 'themes';

import { WORK_STRINGS } from 'screens/work/work.strings';

import { MoneyChange } from '../../money-change';

import {
  INotCompleteWorkProps,
  useNotCompleteWork,
} from './not-complete-work.state';

const { BUTTON, TITLE, WARNING, SKILL, EXPERIENCE } =
  WORK_STRINGS.NOT_PASS_MODAL;

export const NotCompleteWork = (props: INotCompleteWorkProps) => {
  const { onCompleteWork } = props;

  const { salary, notPassLimit } = useNotCompleteWork();

  return (
    <InfoImageModal
      buttonText={BUTTON}
      onCloseModal={onCompleteWork}
      onConfirm={onCompleteWork}
      title={
        <Text typography={typographyCss.RalewayBold20} align="center">
          {TITLE}
        </Text>
      }
      image="notCompleteWork"
      isNotCenter
    >
      <Grid rowGap="16px">
        <Theme.Paragraph $fontFamily="bold" $color="darkRed">
          {WARNING(notPassLimit)}
        </Theme.Paragraph>

        <Grid rowGap="5px">
          <Theme.Paragraph $fontFamily="bold">{SKILL}</Theme.Paragraph>
          <Theme.Paragraph $fontFamily="bold">{EXPERIENCE}</Theme.Paragraph>
        </Grid>

        <MoneyChange salary={salary} />
      </Grid>
    </InfoImageModal>
  );
};
