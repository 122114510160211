import { Theme } from 'themes';
import { InfoImageModal } from 'components/modal';
import { SkillList } from 'components/skills';
import { getModComponent } from 'services/utils';
import { useMod, useReduxSelector } from 'services/hooks';
import { Loader } from 'components/loader';
import { WORK_CONST } from 'constants/work';

import { WORK_STRINGS } from '../../../work.strings';

import { MoneyChange } from '../../money-change';

import { useCompleteWork } from './complete-work.state';

interface ICompleteWorkProps {
  onCompleteWork(): Promise<any> | undefined;
}

const { BUTTON, TITLE, SKILL } = WORK_STRINGS.COMPLETE_WORK;

interface ITitleProps {
  workExperience: number;
}

const Title = (props: ITitleProps) => {
  const { workExperience } = props;

  const isFreelance = useReduxSelector(
    (redux) => redux.player.work?.isFreelance
  );
  const isIntern = useReduxSelector((redux) => redux.player.work?.isIntern);
  const currentCareerExp =
    useReduxSelector((redux) => redux.player.work?.career?.[0].experience) || 0;

  if (!isFreelance && !isIntern) {
    return (
      <Theme.Title $textAlign="center">{TITLE(workExperience)}</Theme.Title>
    );
  }

  const maxCycles = isIntern
    ? WORK_CONST.CYCLES_INTERN
    : WORK_CONST.CYCLES_FREELANCE;

  return (
    <Theme.Wrapper $itemGap={{ default: '16px' }}>
      <Theme.Title $textAlign="center">Done with work!</Theme.Title>
      <div>
        <Theme.Title $textAlign="center">Work experience:</Theme.Title>
        <Theme.Title $textAlign="center">
          {currentCareerExp + 1}/{maxCycles} cycles completed
        </Theme.Title>
      </div>
    </Theme.Wrapper>
  );
};

export const CompleteWork = (props: ICompleteWorkProps) => {
  const { onCompleteWork } = props;

  const { salary, workExperience, learnedAbilities } = useCompleteWork();

  const GaugesDoneWorkMC = getModComponent(
    useMod('gaugesHard')?.components.GaugesDoneWork
  );

  const RenderAbilities = learnedAbilities.length ? (
    <SkillList abilities={learnedAbilities} />
  ) : (
    <Loader variant="buttonLoader" />
  );

  return (
    <InfoImageModal
      isNotCenter
      buttonText={BUTTON}
      onCloseModal={onCompleteWork}
      onConfirm={onCompleteWork}
      title={<Title workExperience={workExperience} />}
      image="completeWork"
    >
      <MoneyChange salary={salary} />

      <Theme.Paragraph $fontFamily="bold">{SKILL}</Theme.Paragraph>

      {RenderAbilities}

      <GaugesDoneWorkMC />
    </InfoImageModal>
  );
};
