import { FC } from 'react';
import { spacing, Theme } from 'themes';

import { ButtonOld } from 'components/button';
import { SHOP_STRINGS } from '../shop.strings';

import { useBuyUtils, useReduxSelector } from 'services/hooks';

interface IShopButtonBarProps {
  onBuy: () => void;
  onGoBack: () => void;

  isDisabledBuy: boolean;

  buyButtonText?: string;
}

const { BUY, GO_BACK } = SHOP_STRINGS.BOTTOM_BAR;

export const ShopButtonBar: FC<IShopButtonBarProps> = (props) => {
  const { onBuy, onGoBack, isDisabledBuy, buyButtonText = BUY } = props;
  const openNegativeBalance = useBuyUtils();
  const money = useReduxSelector((redux) => redux.shop.price.money);

  const onConfirmBuy = () => {
    onBuy();
    openNegativeBalance(money);
  };

  return (
    <Theme.Wrapper $itemGap={{ default: spacing(2) }}>
      <ButtonOld
        isDisabled={isDisabledBuy}
        title={buyButtonText}
        onClick={onConfirmBuy}
      />
      <ButtonOld onClick={onGoBack} title={GO_BACK} styleTheme="cancelModal" />
    </Theme.Wrapper>
  );
};
