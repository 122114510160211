import { useEffect } from 'react';
import { IAbility } from '@avid/common';

import { usePushLog, useReduxSelector, useUpdateState } from 'services/hooks';
import { moveToRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';
import { callFunction } from 'services/api';
import { CLIENT_ID } from 'constants/settings';
import { getBonusAndSkills } from './life-card.utils';
import { LIFE_CARD_ERRORS } from './life-card.const';
import { useDispatch } from 'react-redux';
import { PlayerActions } from 'services/redux';

export enum CardTypeForwardSide {
  AgreeChoose = 'AgreeChoose',
  DisagreeChoose = 'DisagreeChoose',
  NoChoose = 'NoChoose',
}

interface IState {
  cards: ILifeCard[] | null;
  activeIndex: number | null;
  isLoading: boolean;
  isFlipped: boolean;
  forwardCardType: CardTypeForwardSide;
  bonuses: string[] | null;
  abilities?: IAbility[];
}

const INITIAL_STATE: IState = {
  cards: [],
  activeIndex: null,
  isLoading: true,
  isFlipped: false,
  forwardCardType: CardTypeForwardSide.NoChoose,
  bonuses: null,
};

const checkOption = (card: ILifeCard) => card.choose === 'Yes';

const moveToExpenses = () => moveToRoute(ROUTES.lifeIteration);

export const useCard = () => {
  const { state, updateField, updateState } = useUpdateState(INITIAL_STATE);

  const version = useReduxSelector((redux) => redux.app.version);
  const gameSkills = useReduxSelector((redux) => redux.app.skills);
  const maxAbilityLevel = useReduxSelector(
    (redux) => redux.app.MAX_SKILL_LEVEL
  );
  const characterAbilities =
    useReduxSelector((redux) => redux.player.skills) || [];
  const characterSmart = useReduxSelector(
    (redux) => redux.player.createCharacter.smart
  );

  const dispatch = useDispatch();
  const pushLog = usePushLog();

  const card =
    state.cards && state.activeIndex !== null
      ? state.cards[state.activeIndex]
      : null;

  const isOptional = card ? checkOption(card) : false;

  useEffect(() => {
    const request = async () => {
      try {
        const lifeCards = await callFunction('getLifeCards')({
          clientId: CLIENT_ID,
        });

        if (!lifeCards?.lifeCards.length) {
          updateState({ isLoading: false });
          moveToExpenses();
          return;
        }

        updateState({
          isLoading: false,
          cards: lifeCards.lifeCards,
          activeIndex: 0,
        });
      } catch (error) {
        updateState({ isLoading: false, activeIndex: null });
      }
    };

    request();
  }, [updateState]);

  const completeCard = () => {
    const isAvailable = state.cards?.length
      ? state.cards?.length > (state.activeIndex || 0) + 1
      : false;

    if (!isAvailable) {
      return moveToExpenses();
    }

    updateState((current) => ({
      ...current,
      isFlipped: false,
      activeIndex: (current.activeIndex || 0) + 1,
    }));
  };

  const onAgreeLifeCard = async () => {
    if (!card || !version) {
      return;
    }

    const { bonus, incrementedSkills } = getBonusAndSkills({
      card,
      characterAbilities,
      characterSmart,
      gameSkills,
      maxAbilityLevel,
      version,
    });

    updateState({ bonuses: bonus, abilities: incrementedSkills });

    try {
      const result = await callFunction('applyLifeCard')({
        clientId: CLIENT_ID,
        title: card?.title,
      });

      dispatch(PlayerActions.merge(result.mergePlayer));
    } catch (error) {
      console.error(error);

      if ((error as any).message === LIFE_CARD_ERRORS.LIMIT_REACHED) {
        return moveToExpenses();
      }
    }

    const { AP, bank, lifestyle, skills, title, choose } = card;

    return pushLog({
      type: 'lifeCard',
      action: 'complete',
      params: {
        title,
        choose,
        money: bank ? +bank : undefined,
        energy: AP ? +AP : undefined,
        lifestyle,
        skills,
      },
    });
  };

  const onDisagreeCard = () => {
    updateField('forwardCardType')(CardTypeForwardSide.DisagreeChoose);
    updateField('isFlipped')(true);

    return pushLog({
      type: 'lifeCard',
      action: 'disagree',
      params: { title: card?.title || '' },
    });
  };

  const onGetBonus = () => {
    onAgreeLifeCard();

    updateField('isFlipped')(true);

    if (isOptional) {
      updateField('forwardCardType')(CardTypeForwardSide.AgreeChoose);
    }
  };

  return {
    ...state,
    card,
    isOptional,
    onDisagreeCard,
    onGetBonus,
    completeCard,
  };
};
