import { TGameVersion } from '@avid/common';

import { checkEnabledCharacteristic } from 'services/character';
import { uniqueItemArray } from 'services/utils';
import { getPostiveNegative, getSectorCareerTreeAsync } from 'shared';

import { IFormValuesParams } from './work.typings';
import { appFirebase } from 'services/firebase';

interface IGetAllNextLevelsParams {
  version: TGameVersion;
  sector: string;
  work: string;
}

const getNextLevel = (job: string) => (tree: string[]) => {
  const index = tree.indexOf(job);

  if (index !== -1 && index <= tree.length - 2) {
    return tree[index + 1];
  }
};

export const getAllNextLevelsAsync = async (
  params: IGetAllNextLevelsParams
) => {
  const { sector, version, work } = params;

  const trees = await getSectorCareerTreeAsync(version, sector);

  if (trees) {
    const allNextLevel = trees.map(getNextLevel(work));
    const notIdentical = uniqueItemArray(allNextLevel.filter(Boolean));
    return notIdentical as string[];
  }
};

export const formValuesAsync = async (params: IFormValuesParams) => {
  const {
    version,
    chAnchors,
    chValues,
    constAnchors,
    constValues,
    negativeValues,
    positiveValues,
  } = params;

  const characteristicsParams = {
    chAnchors,
    chValues,
    constAnchors,
    constValues,
  };

  const companyValues =
    await appFirebase.firestore.versions.getCompanyValues(version);

  if (!companyValues?.companyValues) {
    throw new Error('no-company-values');
  }

  const positive: ICompanyParameter[] = await Promise.all(
    positiveValues.map((value) => ({
      title: value,
      info: getPostiveNegative(companyValues.companyValues, value, true) || '',
      isEnable: checkEnabledCharacteristic({
        ...characteristicsParams,
        characteristic: value,
      }),
    }))
  );

  const negative: ICompanyParameter[] = await Promise.all(
    negativeValues.map((value) => ({
      title: value,
      info: getPostiveNegative(companyValues.companyValues, value, false) || '',
      isEnable: checkEnabledCharacteristic({
        ...characteristicsParams,
        characteristic: value,
      }),
    }))
  );

  return { positive, negative };
};
