import { TGameVersion } from '@avid/common';

import { VersionsAPI } from 'services/api';
import { appFirebase, db } from 'services/firebase';

import { ICreateCharacterDocument } from 'typings';

const $api = async <T = any>(document: string) =>
  (await db.collection('constants').doc(document).get()).data() as T;

const fetchCharacterConstants = () =>
  $api<ICreateCharacterDocument>('create_character');

const fetchEducationConstants = () => $api<IEducationConstants>('education');

const fetchWorkConstants = async () => {
  const {
    LIMIT_NOT_PASS_WORK_TEST,
    LIMIT_SELECT_SKILL_BEFORE_START_WORK,
    PRICE_WORK_SKILL_CENTER_SELECT,
    PRICE_WORK_SKILL_SELF_SELECT,
    ONE_SKILL_SALARY_BONUS,
    MAX_SKILL_SALARY_BONUS,
    PASS_WORK_PERCENT,
    PRICE_CHANGE_WORK_COMPANY,
    PRICE_SELECT_JOB,
  } = (await $api('work-constants')) as IWorkConstantDocument;

  return {
    LIMIT_NOT_PASS_WORK_TEST,
    LIMIT_SELECT_SKILL_BEFORE_START_WORK,
    PRICE_WORK_SKILL_CENTER_SELECT,
    PRICE_WORK_SKILL_SELF_SELECT,
    ONE_SKILL_SALARY_BONUS,
    MAX_SKILL_SALARY_BONUS,
    PASS_WORK_PERCENT,
    PRICE_CHANGE_WORK_COMPANY,
    PRICE_SELECT_JOB,
  };
};

export const fetchVersionConstants = async (version: TGameVersion) => {
  const [sectors, educationLevels, skills, values] = await Promise.all([
    VersionsAPI.fetchSectors(version),
    VersionsAPI.education.fetchLevels(version),
    VersionsAPI.allSkills.fetchAllSkills(version),
    appFirebase.firestore.versions.getValues(version),
  ]);

  return { educationLevels, sectors, skills, values: values?.values };
};

export const fetchConstants = async () => {
  const [characterConstants, educationConstants, workConstants] =
    await Promise.all([
      fetchCharacterConstants(),
      fetchEducationConstants(),
      fetchWorkConstants(),
    ]);

  return {
    ...characterConstants,
    ...educationConstants,
    ...workConstants,
  };
};
