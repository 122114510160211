import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import { ClientFirebase } from '@avid/firebase';

export const APP_ENV =
  process.env.NODE_ENV === 'development' ? 'local' : 'development';

const KEYS = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_APP,
};

export const app = firebase.initializeApp(KEYS);

export const db = app.firestore();

export const firebaseFunctions = app.functions(
  process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION
);

export const realtimeDatabase = app.database();

export const appAuth = app.auth();

db.settings({
  ignoreUndefinedProperties: true,
});

export const appFirebase = new ClientFirebase(app);
