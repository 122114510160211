import { memo } from 'react';

import { Image } from 'components/image';
import { Loader } from 'components/loader';
import { Theme } from 'themes';

import { ButtonBar, ForwardSideCard, LifeCardInformation } from './components';

import { useCard } from './life-card.state';
import { LIFE_CARD_STRING } from './life-card.const';
import { LifeCardStyles as Styles } from './life-card.styles';

const { TITLE } = LIFE_CARD_STRING.CARD;

export const LifeCard = memo(() => {
  const {
    card,
    bonuses,
    isLoading,
    isOptional,
    abilities,
    forwardCardType,
    isFlipped,

    completeCard,
    onDisagreeCard,
    onGetBonus,
  } = useCard();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Theme.PageContainer $isFullHeight={false}>
      <Styles.Wrapper $isColumn>
        <Theme.Flexbox $isColumn $alignItem="center" $itemGap="20px">
          <Theme.Paragraph $fontFamily="bold">{TITLE}</Theme.Paragraph>

          <LifeCardInformation
            frontSide={
              <Theme.CentralizeFlexbox
                $isColumn
                $itemGap="34px"
                $fontFamily="bold"
              >
                <Image type="startLifeCard" />
                <Theme.Paragraph>{card?.title}</Theme.Paragraph>
              </Theme.CentralizeFlexbox>
            }
            backSide={
              <ForwardSideCard
                description={card?.description || ''}
                abilities={abilities || []}
                forwardCardType={forwardCardType}
                bonuses={bonuses || []}
              />
            }
            isFlipped={isFlipped}
          />
        </Theme.Flexbox>
        <Styles.ButtonsContainer>
          <ButtonBar
            onCloseLifeCard={completeCard}
            onDisagreeCard={onDisagreeCard}
            onGetBonus={onGetBonus}
            isChooseType={isOptional}
            isFlipped={isFlipped}
          />
        </Styles.ButtonsContainer>
      </Styles.Wrapper>
    </Theme.PageContainer>
  );
});
