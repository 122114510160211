import { useBuyUtils, useCanBuy } from 'services/hooks';

interface IProps {
  energy: number;
  onNext: () => void;
  money?: number;
}

export const useStartPhfHard = (props: IProps) => {
  const { energy, money, onNext } = props;

  const isCanBuy = useCanBuy({ energy, money });

  const openNegativeMoney = useBuyUtils();

  const onConfirm = () => {
    onNext();
    openNegativeMoney(money);
  };

  return { isCanBuy, onConfirm };
};
