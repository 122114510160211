import { useCallback, useEffect, useMemo } from 'react';

import { useReduxSelector, useUpdateState } from 'services/hooks';
import { VersionsAPI } from 'services/api';

import {
  IUseCompanyProps,
  ICompanyState,
  CompanyModal,
} from './company.typing';
import { randomNewCompanyIndex, toValuesWithInfo } from './company.utils';
import { WORK_CONST } from 'constants/work';
import { appFirebase } from 'services/firebase';

const INIT_STATE: ICompanyState = {
  isLoading: true,
  previousCompanyIndexes: [],
};

export const useCompany = (props: IUseCompanyProps) => {
  const { sector, job } = props;

  const { state, updateState } = useUpdateState(INIT_STATE);

  const version = useReduxSelector((redux) => redux.app.version);
  const changeCompanyEnergy = useReduxSelector(
    (redux) => redux.app.PRICE_CHANGE_WORK_COMPANY.energy
  );
  const changeCompanyMoney = useReduxSelector(
    (redux) => redux.app.PRICE_CHANGE_WORK_COMPANY.money
  );

  const activeCompany = useMemo(() => {
    const companyFromArray =
      state.companies && state.activeCompanyIndex !== undefined
        ? state.companies[state.activeCompanyIndex]
        : undefined;

    if (!companyFromArray || !state.values) {
      return;
    }

    const { company_name, negative, positive, bonus } = companyFromArray;

    const { positiveWithDescriptions, negativeWithDescriptions } =
      toValuesWithInfo({
        allValues: state.values,
        positive,
        negative,
      });

    return {
      name: company_name,
      values: {
        positive: positiveWithDescriptions,
        negative: negativeWithDescriptions,
      },
      bonus,
    };
  }, [state.activeCompanyIndex, state.companies, state.values]);

  const onSetModal = useCallback(
    (modal?: CompanyModal) => updateState({ modal }),
    [updateState]
  );

  const onClickTryOther = () => onSetModal(CompanyModal.OtherCompany);

  const onCloseModal = () => onSetModal();

  const onChangeActiveCompanyIndex = () =>
    updateState((prev) => {
      const { index, exceed } = randomNewCompanyIndex(
        prev.companies?.length || 0,
        prev.previousCompanyIndexes
      );

      const prevIndexes = exceed ? [] : prev.previousCompanyIndexes;

      return {
        ...prev,
        activeCompanyIndex: index,
        previousCompanyIndexes: [...prevIndexes, index],
      };
    });

  useEffect(() => {
    if (!version) {
      return;
    }

    const request = async () => {
      try {
        const [companyValues, companies, jobDescription] = await Promise.all([
          appFirebase.firestore.versions.getCompanyValues(version),
          VersionsAPI.sectors.work.fetchCompanies({ version, sector }),
          VersionsAPI.sectors.work.job.fetchJobDescription({
            version,
            sector,
            job,
          }),
        ]);

        if (!companyValues?.companyValues || !companies) {
          updateState({ isLoading: false });
          return;
        }

        const { index } = randomNewCompanyIndex(companies.length, []);

        const cycles = jobDescription?.isIntern
          ? WORK_CONST.CYCLES_INTERN
          : jobDescription?.isFreelance
            ? WORK_CONST.CYCLES_FREELANCE
            : undefined;

        updateState({
          isLoading: false,
          values: companyValues.companyValues,
          companies,
          cycles,
          activeCompanyIndex: index,
          previousCompanyIndexes: [index],
        });
      } catch (error) {
        updateState({ isLoading: false });
        console.error(error);
      }
    };

    request();
  }, [job, sector, updateState, version]);

  return {
    ...state,

    activeCompany,
    changeCompanyEnergy,
    changeCompanyMoney,
    onSetModal,
    onCloseModal,
    onClickTryOther,
    onChangeActiveCompanyIndex,
  };
};
