import styled, { css } from 'styled-components';

import { ButtonOld } from 'components/button';
import { MEDIA, spacing } from 'themes';

const ColumnTextCss = css`
  flex-direction: column;

  & > * + * {
    margin-top: ${spacing(1)};
  }

  ${MEDIA.LAPTOP} {
    & > * + * {
      margin-top: ${spacing(2)};
    }
  }
`;

const RowTextCss = css`
  flex-direction: row;

  & > * + * {
    margin-left: ${spacing(1)};
  }

  ${MEDIA.LAPTOP} {
    & > * + * {
      margin-left: ${spacing(2)};
    }
  }
`;

export const CareerStyles = {
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${spacing(2)};
    }

    ${MEDIA.LAPTOP} {
      flex-direction: row;
      justify-content: center;

      & > * + * {
        margin-top: 0;
        margin-left: ${spacing(6)};
      }
    }
  `,
  TextWithTitle: styled.div<{ isColumn?: boolean; justifyContent?: string }>`
    display: flex;

    ${({ justifyContent }) =>
      justifyContent && `justify-content: ${justifyContent}`};

    ${({ isColumn }) => (isColumn ? ColumnTextCss : RowTextCss)};
  `,
  ProgressesContainer: styled.div`
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${spacing(2)};
    }

    ${MEDIA.LAPTOP} {
      flex-direction: row;
      justify-content: center;

      & > * + * {
        margin-top: 0;
        margin-left: ${spacing(5)};
      }
    }
  `,
  Button: styled(ButtonOld)`
    width: 280px;
    height: 62px;
  `,
};
