import { memo } from 'react';
import { Route, Switch } from 'react-router-dom';

import { LifeIteration } from 'screens/life-iteration';
import { useMod } from 'services/hooks';
import { getModComponent } from 'services/utils';
import { ROUTES } from 'constants/routes';

import { LifeCardGate } from '../../life-card';
import { Main } from '../../main';

import { LoanModalContainer } from './loan-modal-container';

export const GameInProgress = memo(() => {
  const ReferralNotificationModalMC = getModComponent(
    useMod('bonusCodes')?.components.ReferralNotificationModal
  );

  return (
    <>
      <Switch>
        <Route path={ROUTES.lifeCard} component={LifeCardGate} />
        <Route path={ROUTES.lifeIteration} component={LifeIteration} />

        <Main />
      </Switch>

      <LoanModalContainer />

      <ReferralNotificationModalMC />
    </>
  );
});
