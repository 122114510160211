import { useCallback } from 'react';

import { useScrollTopOnChangePage, useUpdateState } from 'services/hooks';

import { FindJobPages, IFindJobState } from './find-job.typing';

export const INIT_STATE: IFindJobState = {
  page: FindJobPages.SearchWork,
};

export const useFindJob = () => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  useScrollTopOnChangePage(state.page);

  const setPage = useCallback((page: FindJobPages) => updateState({ page }), [
    updateState,
  ]);

  const setSector = useCallback((sector?: IItem) => updateState({ sector }), [
    updateState,
  ]);

  const setJob = useCallback((job?: string) => updateState({ job }), [
    updateState,
  ]);

  const onRejectCompany = useCallback(() => setPage(FindJobPages.SearchWork), [
    setPage,
  ]);

  const onFind = useCallback(() => setPage(FindJobPages.AvailableWork), [
    setPage,
  ]);

  return { ...state, setPage, setSector, setJob, onFind, onRejectCompany };
};
