import { FC, ReactElement } from 'react';

import { OnetimeClickButton } from 'components/onetime-click-button';
import { ButtonOld } from 'components/button';
import { PriceInformation } from 'components/price-information';
import { spacing, Theme } from 'themes';
import { useBuyUtils, useCanBuy } from 'services/hooks';

import { IModalWrapperProps, ModalWrapper } from './modal-wrapper';

export interface ICostModalProps extends IModalWrapperProps {
  confirmButtonTitle: string;
  cancelButtonTitle: string;
  energy: number;

  onConfirm(): void;

  title?: ReactElement;
  subtitle?: ReactElement;
  money: number | undefined;

  onCancel?: () => void;
}

export const CostModal: FC<ICostModalProps> = (props) => {
  const {
    cancelButtonTitle,
    confirmButtonTitle,
    title,

    energy,
    money,
    subtitle,
    onConfirm,
    onCloseModal,
    onCancel = onCloseModal,

    ...modalProps
  } = props;

  const isCanBuy = useCanBuy({ energy, money });
  const openNegativeMoneyModal = useBuyUtils();

  const onBuyItem = () => {
    if (onConfirm) {
      onConfirm();
    }
    openNegativeMoneyModal(money);
  };

  return (
    <ModalWrapper onCloseModal={onCloseModal} {...modalProps}>
      {title}

      <PriceInformation money={money} energy={energy} />

      {subtitle}

      <Theme.Wrapper $itemGap={{ default: spacing(3) }}>
        <OnetimeClickButton
          onClick={onBuyItem}
          title={confirmButtonTitle}
          isDisabled={!isCanBuy}
        />
        <ButtonOld
          onClick={onCancel}
          title={cancelButtonTitle}
          styleTheme="cancelModal"
        />
      </Theme.Wrapper>
    </ModalWrapper>
  );
};
