import {
  IAbility,
  ICharacterSmart,
  ISkillInfo,
  TGameVersion,
} from '@avid/common';

import { createPrice } from 'services/utils';
import { getIncrementedAbilities, getSkillsInfoByNames } from 'shared';

const addArticle = (value: number) => (value < 0 ? 'Minus' : 'Plus');

const getSkillNames = (skills: string) =>
  skills.split('AND').map((skill) => skill.trim());

interface IGetCardBonusAndSkillsParams {
  card: ILifeCard;
  version: TGameVersion;
  characterAbilities: IAbility[];
  characterSmart: ICharacterSmart;
  gameSkills: ISkillInfo[];
  maxAbilityLevel: number;
}

export const getBonusAndSkills = (params: IGetCardBonusAndSkillsParams) => {
  const bonus: string[] = [];
  let incrementedSkills: IAbility[] | undefined = undefined;

  const {
    card,
    characterAbilities,
    characterSmart,
    maxAbilityLevel,
    version,
    gameSkills,
  } = params;

  const { AP, bank, lifestyle, possession, skills } = card;

  if (!!AP) {
    const ap = +AP;
    bonus.push(`${addArticle(ap)} ${Math.abs(ap)} Energy`);
  }

  if (!!bank) {
    const money = +bank;
    bonus.push(`${addArticle(money)} ${createPrice(Math.abs(money))}`);
  }

  if (skills && version) {
    const skillNames = getSkillNames(skills);
    const skillList = getSkillsInfoByNames(gameSkills, skillNames);

    const skillInfos = skillList.filter(Boolean) as ISkillInfo[];

    incrementedSkills = getIncrementedAbilities({
      characterAbilities,
      skillInfos,
      characterSmart,
      maxAbilityLevel,
    });
  }
  if (lifestyle) {
    bonus.push(lifestyle);
  }
  if (possession) {
    bonus.push(possession);
  }

  return { bonus, incrementedSkills };
};
