import { ICreateCharacterConstants } from '@avid/common';

import { action } from 'services/utils';

import { APP_ACTION, IGameConstants } from './app.constants';

const {
  UPDATE_CONSTANTS,
  CLOSE_NEGATIVE_MONEY,
  OPEN_NEGATIVE_MONEY,
  UPDATE_GAME_CONSTANTS,
  SET_MODAL,
} = APP_ACTION;

type TAppConstants = IEducationConstants &
  ICreateCharacterConstants &
  IVersionConstants;

export const AppActions = {
  updateConstants: (constants: Partial<TAppConstants>) =>
    action(UPDATE_CONSTANTS, constants),
  updateGameConstants: (payload: Partial<IGameConstants>) =>
    action(UPDATE_GAME_CONSTANTS, payload),
  openNegativeMoney: () => action(OPEN_NEGATIVE_MONEY),
  closeNegativeMoney: () => action(CLOSE_NEGATIVE_MONEY),
  setModal: (isOpen: boolean) => action(SET_MODAL, isOpen),
};

export type TAppActions = ReturnType<TActionCombiner<typeof AppActions>>;
