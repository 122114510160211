import { Backdrop, Text } from 'components/ui';
import { typographyCss } from 'themes';

import { SmartPopupStyles as Styles } from './smart-popup.styles';

interface IProps {
  title: string;
  description: string;
  value: number;
  onIncrease: () => void;
  onDecrease: () => void;
  onClose: () => void;
}

export const SmartPopup = (props: IProps) => {
  const { title, description, value, onIncrease, onDecrease, onClose } = props;

  return (
    <>
      <Styles.Root>
        <Styles.CloseIcon type="remove" onClick={onClose} />

        <Text as="h2">{title}</Text>

        <Text>{description}</Text>

        <Styles.ControlsWrapper>
          <Styles.MinusControl onClick={onDecrease} />

          <Text as="b" typography={typographyCss.titleBold}>
            {value}
          </Text>

          <Styles.PlusControl onClick={onIncrease} />
        </Styles.ControlsWrapper>
      </Styles.Root>

      <Backdrop />
    </>
  );
};
