import {
  ICreateCharacterConstants,
  IGameMods,
  ISector,
  ISkillInfo,
  IWorkConstants,
  TGameVersion,
} from '@avid/common';

export const APP_ACTION = <const>{
  UPDATE_CONSTANTS: '@APP/UPDATE_CONSTANTS',
  OPEN_NEGATIVE_MONEY: '@APP/OPEN_NEGATIVE_MONEY',
  CLOSE_NEGATIVE_MONEY: '@APP/CLOSE_NEGATIVE_MONEY',
  UPDATE_GAME_CONSTANTS: '@APP/UPDATE_GAME_CONSTANTS',
  SET_MODAL: '@APP/SET_MODAL',
};

export interface IGameConstants {
  minimalMoney: number;
  startEducationOrder?: number;
  version?: TGameVersion;
  gameMods?: IGameMods;
}

export type IAppRedux = IEducationConstants &
  ICreateCharacterConstants &
  IWorkConstants &
  IGameConstants & {
    educationLevels: TEducationLevels;
    skills: ISkillInfo[];
    sectors: ISector[];
    isOpenNegativeMoneyModal: boolean;
    isScreenModalOpen: boolean;
    values: Record<string, string[]>;
  };

const DEFAULT_PRICE: IPrice = { energy: 0 };

export const INIT_REDUX_CONSTANTS: IAppRedux = {
  MAX_SKILL_LEVEL: 0,
  PASS_TEST_RATING: 0,
  sectors: [],
  educationLevels: {},
  skills: [],

  isOpenNegativeMoneyModal: false,

  minimalMoney: 0,

  anchors: [],
  values: {},

  LIMIT_SELECT_SKILL_BEFORE_START_WORK: 0,
  LIMIT_NOT_PASS_WORK_TEST: 0,
  ONE_SKILL_SALARY_BONUS: 0,
  MAX_SKILL_SALARY_BONUS: 0,
  PASS_WORK_PERCENT: 0,

  PRICE_WORK_SKILL_CENTER_SELECT: DEFAULT_PRICE,
  PRICE_WORK_SKILL_SELF_SELECT: DEFAULT_PRICE,
  PRICE_CHANGE_WORK_COMPANY: DEFAULT_PRICE,
  PRICE_SELECT_JOB: DEFAULT_PRICE,

  isScreenModalOpen: false,
};

export const LOCAL_STORAGE_EVENT = 'tab-restrict';
