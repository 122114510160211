import { FC, ReactElement } from 'react';
import { IAbility } from '@avid/common';

import { Image, TImageNames } from 'components/image';
import { Theme } from 'themes';

import { LIFE_CARD_STRING } from '../life-card.const';
import { CardTypeForwardSide } from '../life-card.state';

import { BonusList } from './bonus-list';
interface IForwardSideCardProps {
  description: string;
  abilities: IAbility[];
  bonuses: string[];
  forwardCardType: CardTypeForwardSide;
}

const { DISAGREE_CHOOSE_CARD } = LIFE_CARD_STRING;

const SideImage: FC<{ image: TImageNames }> = (props) => (
  <Image width="100px" height="150px" type={props.image} />
);

export const ForwardSideCard: FC<IForwardSideCardProps> = (props) => {
  const { abilities, bonuses, forwardCardType, description } = props;

  const Description = () => <Theme.Paragraph>{description}</Theme.Paragraph>;

  const ForwardSide: Record<CardTypeForwardSide, ReactElement> = {
    [CardTypeForwardSide.AgreeChoose]: (
      <>
        <SideImage image="agreeCard" />
        <Description />
        <BonusList abilities={abilities} bonuses={bonuses} />
      </>
    ),
    [CardTypeForwardSide.NoChoose]: (
      <>
        <Description />
        <BonusList abilities={abilities} bonuses={bonuses} />
      </>
    ),
    [CardTypeForwardSide.DisagreeChoose]: (
      <>
        <Theme.Title>{DISAGREE_CHOOSE_CARD}</Theme.Title>
        <SideImage image="disagreeCard" />
      </>
    ),
  };

  return <>{ForwardSide[forwardCardType]}</>;
};
