import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { usePlayerSalary, usePushLog } from 'services/hooks';
import { moveToRoute } from 'services/utils';
import { ROUTES } from 'constants/routes';
import { PlayerActions } from 'services/redux';
import { callFunction } from 'services/api';
import { CLIENT_ID } from 'constants/settings';

export const useLiveExpenses = () => {
  const { salary = 0 } = usePlayerSalary();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const pushLog = usePushLog();

  const getLiveExpenses = () => {
    if (salary < 5000) {
      return 500;
    }
    if (salary <= 10000) {
      return 1000;
    }
    return 2000;
  };

  const payLiveExpenses = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const spent = -getLiveExpenses();

    try {
      const result = await callFunction('payLifeExpenses')({
        clientId: CLIENT_ID,
      });

      if (result?.mergePlayer) {
        dispatch(PlayerActions.merge(result.mergePlayer));

        pushLog({
          type: 'expenses',
          action: 'deduct',
          params: { money: spent },
        });
      }

      moveToRoute(ROUTES.home);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    payLiveExpenses,
    getLiveExpenses,
  };
};
