import { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { PlayerActions } from 'services/redux';
import { callFunctionAction } from 'services/api';
import {
  useMod,
  usePlayerSalary,
  usePushLog,
  useReduxSelector,
} from 'services/hooks';

import { IWorkState, WorkModal } from '../../../work.typings';
import { WorkContext } from '../../../work.context';

import { IEndWorkProps } from './end-work.typing';

export const useEndWork = (props: IEndWorkProps) => {
  const { rating } = props;

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { onReset, onSetNextLevels } = useContext(WorkContext);

  const job = useReduxSelector((redux) => redux.player.work?.work);
  const workMood = useReduxSelector((redux) => redux.player.work?.mood);
  const isGauges = useReduxSelector((redux) =>
    Boolean(redux.app.gameMods?.gauges)
  );
  const isProposed = useReduxSelector(
    (redux) => redux.player.financial?.isProposed
  );

  const { salary } = usePlayerSalary();

  const dispatch = useDispatch();
  const pushLog = usePushLog();

  const gaugesHard = useMod('gaugesHard');

  const onCompleteWork = async () => {
    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);

    try {
      const result = await callFunctionAction('@WORK/END_WORK', { rating });

      dispatch(PlayerActions.merge(result.mergePlayer));

      let resetPayload: Partial<IWorkState> | undefined = undefined;

      if (result.isPassed === undefined) {
        resetPayload = { modal: WorkModal.FailedWork };
        pushLog({ type: 'work', action: 'quit', params: { reason: 'fail' } });
      } else if (result.isLastAvailableCycle) {
        resetPayload = { modal: WorkModal.CyclesExceed };
        pushLog({ type: 'work', action: 'quit', params: { reason: 'own' } });
      } else {
        if (isProposed === false) {
          resetPayload = { modal: WorkModal.BudgetingPropose };
        } else if (result.promotes?.length) {
          resetPayload = { modal: WorkModal.CareerUp };
          onSetNextLevels(result.promotes);
        }

        const isWorkMood = workMood !== undefined && workMood !== 0;

        pushLog({
          type: 'work',
          action: 'cycle',
          params: {
            position: job || 'UNKNOWN',
            money: salary,
            satisfaction: isWorkMood && !isGauges ? workMood : undefined,
            energy:
              gaugesHard && isWorkMood
                ? gaugesHard.constants.WORK_MOOD_ENERGY_MAP[workMood]
                : undefined,
            gauges:
              isWorkMood && isGauges
                ? {
                    work: isWorkMood && isGauges ? workMood : undefined,
                  }
                : undefined,
          },
        });
      }

      onReset(resetPayload);
    } catch (error) {
      console.error(error);
      onReset();
    } finally {
      setIsSubmitted(false);
    }
  };

  return { onCompleteWork };
};
