import { Loader } from 'components/loader';
import { LogList, LogsLoader } from './components';
import { IHomeHistoryProps, useHomeHistory } from './history.state';
import { HistoryStyles as Styles } from './history.styles';

const SORT_BY = 'Sort by: Most Recent';

export const History = (props: IHomeHistoryProps) => {
  const {
    isLoaded,
    isUpdating,
    logs,
    isShowButton,
    onClickButton,
  } = useHomeHistory(props);

  const RenderMoreButton = isShowButton && (
    <Styles.LoadButton
      title="More"
      styleTheme="orange"
      onClick={onClickButton}
    />
  );

  const RenderLoader = isUpdating && (
    <Styles.UpdatingLoaderContainer>
      <Loader variant="buttonLoader" />
    </Styles.UpdatingLoaderContainer>
  );

  const RenderChildren = isLoaded ? (
    <Styles.Content>
      <Styles.SortLabel>{SORT_BY}</Styles.SortLabel>
      <LogList renderLogs={logs} />

      {RenderMoreButton}

      {RenderLoader}
    </Styles.Content>
  ) : (
    <LogsLoader />
  );

  return <Styles.Root>{RenderChildren}</Styles.Root>;
};
