import { useContext, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { IAbility } from '@avid/common';

import { useReduxSelector, useTotalSalary } from 'services/hooks';
import { PlayerActions } from 'services/redux';
import { callFunctionAction } from 'services/api';

import { WorkContext } from '../../../work.context';

export const useCompleteWork = () => {
  const [learnedAbilities, setLearnedAbilities] = useState<IAbility[]>([]);

  const { skills } = useContext(WorkContext);

  const sector = useReduxSelector((redux) => redux.player.work?.sector);
  const job = useReduxSelector((redux) => redux.player.work?.work);
  const companyBonus = useReduxSelector(
    (redux) => redux.player.work?.company.bonus
  );
  const workExperience = useReduxSelector(
    (redux) => redux.player.work?.experience,
    shallowEqual
  );
  const characterSmart = useReduxSelector(
    (redux) => redux.player.createCharacter.smart,
    shallowEqual
  );
  const maxAbilityLevel = useReduxSelector(
    (redux) => redux.app.MAX_SKILL_LEVEL
  );

  const dispatch = useDispatch();

  const { totalSalary } = useTotalSalary({ companyBonus, job, sector });

  const workExperienceRef = useRef(
    ((workExperience && sector && workExperience[sector]) || 0) + 1
  );

  useEffect(() => {
    const request = async () => {
      try {
        const result = await callFunctionAction('@WORK/INCREMENT_SKILLS', {
          skillNames: skills.map((skill) => skill.name),
        });

        setLearnedAbilities(result.incrementedSkills);

        dispatch(PlayerActions.merge(result.mergePlayer));
      } catch (error) {
        console.error(error);
      }
    };

    request();
  }, [characterSmart, dispatch, maxAbilityLevel, skills]);

  return {
    learnedAbilities,
    salary: totalSalary,
    workExperience: workExperienceRef.current,
  };
};
